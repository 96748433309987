import { fltRound, transformAsCurrency } from '~/utils/functions';
import { SearchProps } from '../protocols/SearchProps';
import { validarTesourariaProps } from '../protocols/TesourariaProps';

export function ValidarToleranciaTesouraria(
  searchRows: SearchProps,
  val_tolerancia_tes: number,
  searchRowValidado: validarTesourariaProps,
): validarTesourariaProps {
  const base = {
    cod_loja: searchRows.cod_loja,
    dta_mov: searchRows.dta_mov,
    cod_caixa: searchRows.num_pdv,
    cod_operador: searchRows.cod_operador,
  };

  function update(val: any) {
    const objetoUpdate = searchRowValidado.update;
    val.cod_tesouraria = searchRows.cod_tesouraria;
    val.val_conf = transformAsCurrency(val.val_conf);
    const objBase = { ...base, ...val };
    objetoUpdate.push(objBase);
    searchRowValidado.update = objetoUpdate;
  }
  function insert(val: any) {
    const objetoInsert = searchRowValidado.insert;
    val.val_conf = transformAsCurrency(val.val_conf);
    const objBase = { ...base, ...val };
    objetoInsert.push(objBase);
    searchRowValidado.insert = objetoInsert;
  }

  if (searchRows.tipo_status === 0) {
    if (Number(fltRound(transformAsCurrency(searchRows.val_dif), 2)) === 0) {
      update({
        tipo_status: 1,
        val_conf: Math.trunc(Number(transformAsCurrency(searchRows.val_conf))),
        flg_conferido: true,
      });
      return searchRowValidado;
    }

    //  Verifica se o total esta dentro do limite informado na regra da empresa
    if (
      Number(fltRound(transformAsCurrency(searchRows.val_dif), 2)) <=
      fltRound(val_tolerancia_tes, 2)
    ) {
      if (Number(fltRound(transformAsCurrency(searchRows.val_dif), 2)) === 0) {
        update({
          tipo_status: 1,
          val_conf: fltRound(transformAsCurrency(searchRows.val_conf), 2),
          flg_conferido: true,
        });
      } else {
        update({
          tipo_status: 1,
          val_conf: fltRound(transformAsCurrency(searchRows.val_conf), 2),
          flg_conferido: true,
        });
        insert({
          val_conf: Math.abs(
            fltRound(
              transformAsCurrency(searchRows.val_mov) -
                transformAsCurrency(searchRows.val_conf),
              2,
            ),
          ),
          cod_finalizadora: searchRows.cod_finalizadora,
          num_turno: searchRows.num_turno,
          tipo_status: 4,
          qtd_registro: 1,
          hra_abertura: searchRows.hra_abertura,
          hra_fechamento: searchRows.hra_fechamento,
        });
      }

      return searchRowValidado;
      // eslint-disable-next-line no-else-return
    } else {
      if (Number(fltRound(transformAsCurrency(searchRows.val_dif), -2)) !== 0) {
        searchRows.tipo_status = 2;
        update({
          tipo_status: 2,
          val_conf: fltRound(transformAsCurrency(searchRows.val_conf), 2),
          flg_conferido: false,
        });
        return searchRowValidado;
      }
      update({
        tipo_status: 1,
        val_conf: fltRound(transformAsCurrency(searchRows.val_conf), 2),
        flg_conferido: true,
      });
    }
  }

  return searchRowValidado;
}
