import api from '~/services/api';
import { toast } from 'react-toastify';
import { CreatePDFProps, ParamcreatePDFProps } from '../../protocols/PdfProps';

export async function createPDF(
  params: ParamcreatePDFProps,
): Promise<CreatePDFProps> {
  try {
    const { data } = await api.post(`/conferencia-tesouraria/pdfReport`, {
      ...params,
    });
    return data;
  } catch (error: any) {
    toast.warning(error.message);
    return error;
  }
}
