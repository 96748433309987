import React from 'react';
import { ConferenciaTesourariaContextProvider } from './ConferenciaTesourariaContext';
import { ConferenciaTesourariaContent } from './ConferenciaTesourariaContent';

export const ConferenciaTesouraria: React.FC = () => {
  return (
    <ConferenciaTesourariaContextProvider>
      <ConferenciaTesourariaContent />
    </ConferenciaTesourariaContextProvider>
  );
};
