import api from '~/services/api';
import { toast } from 'react-toastify';
import { ParamSearchProps } from '../../protocols/SearchProps';

export async function deleteTesouraria(
  params: ParamSearchProps,
): Promise<boolean> {
  try {
    const { data } = await api.delete(`/conferencia-tesouraria`, {
      params,
    });

    if (data.success) {
      toast.success(`Tesouraria excluida com sucesso`);
    }
    return data.success;
  } catch (error: any) {
    toast.warning(error.message);
    return false;
  }
}
