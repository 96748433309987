import React from 'react';

import { Row, Col } from 'react-bootstrap';

import { CgSmile, CgSmileNeutral, CgSmileSad } from 'react-icons/cg';
import useAuth from '~/hooks/useAuth';
import {
  fltRound,
  formatCurrencyAsText,
  transformAsCurrency,
} from '~/utils/functions';
import { formatCurrency } from '../../functions/FormatCurrency';
import { useConferenciaTesouraria } from '../../ConferenciaTesourariaContext';
import { SearchProps } from '../../protocols/SearchProps';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core';
import { ContainerPagination } from '../../styles';

export const DataGridComponent: React.FC = () => {
  const user = useAuth();
  const {
    setValue,
    register,
    errors,
    handlesearchRows,
    searchRows,
    handleTotal,
  } = useConferenciaTesouraria();

  const Paginated: React.FC = () => {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <p className="my-2">
            {`${searchRows.length} registro(s) encontrado(s)`}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <Row
        style={{
          marginTop: '10px',
        }}
      >
        <Col md={12} sm={12}>
          <Paper style={{ width: '100%' }}>
            <TableContainer style={{ height: '350px' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Finalizadora</TableCell>
                    {user.user.flg_lib_financeiro && (
                      <TableCell align="right">Qtd. Registro</TableCell>
                    )}
                    {user.user.flg_lib_financeiro && (
                      <TableCell align="right">Valor Mov.</TableCell>
                    )}
                    <TableCell align="right">Conferência</TableCell>
                    <TableCell align="center">Turno</TableCell>
                    <TableCell align="center">Restrição</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchRows.map((row) => {
                    return (
                      <TableRow key={row.cod_tesouraria}>
                        <TableCell
                          style={{
                            width: `${
                              user.user.flg_lib_financeiro ? '' : '60%'
                            }`,
                          }}
                        >
                          {row.des_finalizadora}
                        </TableCell>

                        {user.user.flg_lib_financeiro && (
                          <TableCell
                            style={{
                              width: '120px',
                              textAlign: 'right',
                            }}
                          >
                            {row.qtd_registro}
                          </TableCell>
                        )}
                        {user.user.flg_lib_financeiro && (
                          <TableCell
                            className={`${
                              user.user.flg_lib_financeiro ? 'green' : ''
                            }`}
                            style={{
                              width: '120px',
                              textAlign: 'right',
                            }}
                          >
                            {row.val_mov}
                          </TableCell>
                        )}
                        {Number(row.tipo_status) === 0 ? (
                          <TableCell
                            style={{
                              width: '100px',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <input
                              style={{ width: '120px', textAlign: 'right' }}
                              placeholder="0,00"
                              {...register(
                                `cod_tesouraria-${row.cod_tesouraria}`,
                              )}
                              className={
                                errors.teste
                                  ? 'form-control is-invalid'
                                  : 'form-control'
                              }
                              type="string"
                              onChange={(event: any) => {
                                setValue(
                                  `cod_tesouraria-${row.cod_tesouraria}`,
                                  formatCurrency(Number(event.target.value), 2),
                                );

                                const indexTesouraria = searchRows.findIndex(
                                  (item: SearchProps) => {
                                    return (
                                      item.cod_tesouraria === row.cod_tesouraria
                                    );
                                  },
                                );

                                searchRows[indexTesouraria].val_dif =
                                  formatCurrencyAsText(
                                    Math.abs(
                                      fltRound(
                                        transformAsCurrency(
                                          searchRows[indexTesouraria]?.val_mov,
                                        ) -
                                          transformAsCurrency(
                                            event.target.value,
                                          ),
                                        2,
                                      ),
                                    ),
                                  );

                                searchRows[indexTesouraria].val_conf =
                                  formatCurrency(Number(event.target.value), 2);

                                handlesearchRows(searchRows);

                                let totalSearch = 0;
                                for (
                                  let index = 0;
                                  index < searchRows.length;
                                  index++
                                ) {
                                  totalSearch += transformAsCurrency(
                                    searchRows[index].val_conf,
                                  );
                                }

                                handleTotal(formatCurrencyAsText(totalSearch));
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell
                            style={{
                              textAlign: 'right',
                              paddingRight: '20px',
                            }}
                          >
                            {row.val_conf}
                          </TableCell>
                        )}
                        <TableCell
                          style={{ width: '120px', textAlign: 'center' }}
                        >
                          {row.num_turno}
                        </TableCell>

                        <TableCell style={{ textAlign: 'center' }}>
                          {Number(row.tipo_status) === 1 && (
                            <CgSmile size={30} style={{ color: '#72ab90' }} />
                          )}
                          {Number(row.tipo_status) === 2 && (
                            <CgSmileSad
                              size={30}
                              style={{ color: '#ed281a' }}
                            />
                          )}
                          {Number(row.tipo_status) === 3 && (
                            <CgSmileNeutral
                              size={30}
                              style={{ color: '#f2bd00' }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {searchRows.length === 0 && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={6}
                      style={{ height: '280px' }}
                    >
                      Nenhum registro encontrado...
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
            <ContainerPagination>
              <Paginated />
            </ContainerPagination>
          </Paper>
        </Col>
      </Row>
    </>
  );
};
