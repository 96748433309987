/* eslint-disable no-lonely-if */
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  Control,
  FieldErrorsImpl,
  FieldValues,
  useForm,
  UseFormGetValues,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
} from 'react-hook-form';
import { SelectProps } from './protocols/SelectProps';
import { getSearch } from './Services/getSearch';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { getTurno } from './Services/getTurno';
import { toast } from 'react-toastify';
import { getOperador } from './Services/getOperador';
import { getPdvs } from './Services/getPdvs';
import { createPDF } from './Services/createPDF';
import { SearchProps } from './protocols/SearchProps';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { deleteTesouraria } from './Services/deleteTesouraria';
import { getStatusTesouraria } from './Services/getStatusTesouraria';
import { getToleranciaEmpresa } from './Services/getToleranciaEmpresa';
import { ValidarToleranciaTesouraria } from './functions/ValidarTesouraria';
import { VerificarTesouraria } from './functions/VerificarTesouraria';
import { validarTesouraria } from './Services/validarTesouraria';
import { ParamcreatePDFProps } from './protocols/PdfProps';
import { LojaContext } from '~/context/loja';

interface ConferenciaTesourariaContextData {
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrorsImpl<{
    [x: string]: any;
  }>;
  getValues: UseFormGetValues<FieldValues>;
  reset: UseFormReset<FieldValues>;
  control: Control<FieldValues, any>;

  handleSearch: () => void;
  handledisableCaixa: (value: boolean) => void;
  handledisableTurno: (value: boolean) => void;
  handledisableOperador: (value: boolean) => void;
  handledisableButtonCancel: (value: boolean) => void;
  handledisableButtonClear: (value: boolean) => void;
  handlesearchRows: (value: SearchProps[]) => void;
  handleOptionsPdv: (value: SelectProps[]) => void;
  handleOptionsTurno: (value: SelectProps[]) => void;
  handleOptionsOperador: (value: SelectProps[]) => void;
  handleTotal: (value: string) => void;
  handleLoja: (value: number) => void;
  resetInput: () => void;
  handleCancel: () => void;
  handleGetTurnos: () => void;
  handleChangeCaixa: () => void;
  handleGetPdv: () => void;
  handleChangeLojaAndDate: () => void;
  handleValidar: () => void;
  handleImprimir: () => void;
  handleDelete: () => void;
  handleDisableWhenSearch: (value: boolean) => void;

  loaderSearch: boolean;
  disableCaixa: boolean;
  disableTurno: boolean;
  disableOperador: boolean;
  disableButtonCancel: boolean;
  disableButtonClear: boolean;
  searchRows: SearchProps[];
  total: string;
  lojaConferencia: number;
  optionsTurno: SelectProps[];
  optionsOperador: SelectProps[];
  optionsPdv: SelectProps[];
  disableButtonValidar: boolean;
  disabledButtonImprimir: boolean;
  setFocus: any;
  disableInputs: boolean;
  disableButtonSearch: boolean;
  loader: boolean;
}

interface ProviderConferenciaTesourariaProps {
  children: ReactNode;
}

export const ConferenciaTesourariaContext = createContext(
  {} as ConferenciaTesourariaContextData,
);

export function ConferenciaTesourariaContextProvider({
  children,
}: ProviderConferenciaTesourariaProps): JSX.Element {
  const { loja } = useContext(LojaContext);
  const [loaderSearch, setLoadersearch] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [lojaConferencia, setLojaConferencia] = useState<number>(loja.cod_loja);
  const [optionsPdv, setOptionsPdv] = useState<SelectProps[]>([]);
  const [optionsTurno, setOptionsTurno] = useState<SelectProps[]>([]);
  const [optionsOperador, setOptionsOperador] = useState<SelectProps[]>([]);
  const [disableCaixa, setDisableCaixa] = useState<boolean>(true);
  const [disableTurno, setDisableTurno] = useState<boolean>(true);
  const [disableOperador, setDisableOperador] = useState<boolean>(true);
  const [disableButtonCancel, setDisableButtonCancel] = useState<boolean>(true);
  const [disableButtonClear, setDisableButtonClear] = useState<boolean>(false);
  const [disableButtonValidar, setDisableButtonValidar] =
    useState<boolean>(true);
  const [searchRows, setSearchRows] = useState<SearchProps[]>([]);
  const [total, setTotal] = useState<string>('0,00');
  const [disabledButtonImprimir, setDisabledButtonImprimir] =
    useState<boolean>(true);
  const [disableInputs, setDisableInputs] = useState<boolean>(false);
  const [disableButtonSearch, setDisableButtonSearch] =
    useState<boolean>(false);

  const MySwal = withReactContent(Swal);

  /**
   * useForm para controle do formulário Pessoa
   */
  const {
    register,
    control,
    reset,
    setValue,
    getValues,
    setFocus,
    unregister,
    formState: { errors },
  } = useForm();

  const handledisableCaixa = useCallback((value: boolean) => {
    setDisableCaixa(value);
  }, []);
  const handledisableTurno = useCallback((value: boolean) => {
    setDisableTurno(value);
  }, []);
  const handledisableOperador = useCallback((value: boolean) => {
    setDisableOperador(value);
  }, []);
  const handledisableButtonCancel = useCallback((value: boolean) => {
    setDisableButtonCancel(value);
  }, []);
  const handledisableButtonClear = useCallback((value: boolean) => {
    setDisableButtonClear(value);
  }, []);
  const handleDisableWhenSearch = useCallback((value: boolean) => {
    setDisableInputs(value);
    setDisableCaixa(value);
    setDisableOperador(value);
    setDisableTurno(value);
    setDisableButtonSearch(value);
  }, []);

  const handlesearchRows = useCallback(
    (value: SearchProps[]) => {
      setValue('searchValue', value);
      setSearchRows(value);
    },
    [setValue],
  );
  const handleTotal = useCallback(
    (value: string) => {
      setValue('total', value);
      setTotal(value);
    },
    [setValue],
  );
  const handleLoja = useCallback(
    (value: number) => {
      setLojaConferencia(value);
      setValue('lojas', value);
    },
    [setValue],
  );
  const handleOptionsTurno = useCallback((value: SelectProps[]) => {
    setOptionsTurno(value);
  }, []);
  const handleOptionsOperador = useCallback((value: SelectProps[]) => {
    setOptionsOperador(value);
  }, []);
  const handleOptionsPdv = useCallback((value: SelectProps[]) => {
    setOptionsPdv(value);
  }, []);

  const handleImprimir = useCallback(async () => {
    const { lojas, operador, searchValue } = getValues();
    const params: ParamcreatePDFProps = {
      lojas,
      cod_operador: operador.value,
      searchRows: searchValue,
    };
    const { pdf } = await createPDF(params);
    if (pdf) {
      const byteArray = convertBase64ToBlob(pdf);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(blob);
      const windowReference = window.open(fileURL, 'height=500,width=500');
      if (!windowReference) {
        toast.warning('O pop-up de impressão foi bloqueado pelo navegador.');
      }
    } else {
      toast.warning('Não foi possivel gerar o relatorio');
    }
  }, [searchRows, getValues]);

  const convertBase64ToBlob = (base64String: string): any => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
  };

  const handleGetPdv = useCallback(async () => {
    const { dta_mov } = getValues();

    const pdvs = await getPdvs(dta_mov, lojaConferencia);

    setValue('caixa', '');
    setValue('operador', '');
    handleOptionsOperador([]);

    if (pdvs.length === 0) {
      handleOptionsPdv([]);
      return toast.warning('Caixa não encontrado para a data da movimentação ');
    }

    const optionsCaixa = pdvs.map((value: any) => {
      return { value: value.num_pdv, label: String(value.num_pdv) };
    });
    handledisableCaixa(false);
    handleOptionsPdv(optionsCaixa);
  }, [
    getValues,
    handleOptionsOperador,
    handleOptionsPdv,
    setValue,
    handledisableCaixa,
    lojaConferencia,
  ]);

  const handleGetStatusTesouraria = useCallback(async () => {
    const { dta_mov, caixa, lojas } = getValues();
    const statusTesouraria = await getStatusTesouraria(
      dta_mov,
      caixa.value,
      lojas,
    );

    return statusTesouraria;
  }, [getValues]);

  const handleChangeLojaAndDate = useCallback(() => {
    const { dta_mov } = getValues();

    if (lojaConferencia > 0 && dta_mov) {
      setValue('lojas', lojaConferencia);
      handleGetPdv();
    } else {
      handledisableCaixa(true);
    }
  }, [getValues, handleGetPdv, handledisableCaixa, lojaConferencia, setValue]);

  const handleGetTurnos = useCallback(async () => {
    const { dta_mov, lojas, caixa, operador } = getValues();
    const turnos = await getTurno(dta_mov, caixa.value, operador.value, lojas);

    handleOptionsTurno(turnos);
  }, [getValues, handleOptionsTurno]);

  const resetInput = useCallback(async () => {
    handleLoja(loja.cod_loja);
    reset();
    setValue('dta_mov', '');
    setValue('caixa', '');
    setValue('operador', '');
    setValue('turno', '');
    handledisableCaixa(true);
    handledisableOperador(true);
    handledisableTurno(true);
  }, [
    reset,
    handleLoja,
    handledisableCaixa,
    handledisableOperador,
    handledisableTurno,
    setValue,
    loja.cod_loja,
  ]);

  const handleSearch = useCallback(async () => {
    const { dta_mov, lojas, caixa, operador, turno } = getValues();

    if (dta_mov && caixa && operador) {
      setLoadersearch(true);

      const search = await getSearch(
        dta_mov,
        caixa.value,
        operador.value,
        lojas,
        turno.value,
      );

      if (caixa.value && operador.value) handledisableTurno(false);
      else handledisableTurno(true);

      let totalSearch = 0;
      let buttonImprimirDisabled = true;

      for (let index = 0; index < search.length; index++) {
        totalSearch += transformAsCurrency(search[index].val_conf);
        setValue(
          `cod_tesouraria-${search[index].cod_tesouraria}`,
          search[index].val_conf,
        );
        if (
          search[index].tipo_status === 1 ||
          search[index].tipo_status === 2
        ) {
          buttonImprimirDisabled = false;
        }
      }

      setDisabledButtonImprimir(buttonImprimirDisabled);
      setTotal(formatCurrencyAsText(totalSearch));
      if (search.length > 0) {
        handleGetTurnos();
        setDisableButtonCancel(false);
        setDisableButtonClear(true);
        setDisableButtonValidar(false);
        handleDisableWhenSearch(true);
      } else {
        toast.warning(
          'Nenhum registro encontrado, conforme critérios informados!',
        );
      }
      handlesearchRows(search);
      setLoadersearch(false);
    } else {
      const campos = [
        { name: 'Data da Movimentação', value: dta_mov },
        { name: 'Caixa', value: caixa.value },
        { name: 'Operador', value: operador.value },
      ];

      let messageToastInput = 'Para pesquisar, o campo ';
      const inputArr: any = [];

      campos.forEach(({ name, value }) => {
        if (!value) inputArr.push(name);
      });

      inputArr.forEach((name: string, index: number) => {
        if (inputArr.length - 1 === index) {
          messageToastInput += `${inputArr.length === 1 ? '' : ' e '}`;
          messageToastInput += `${name}`;
        } else {
          messageToastInput += `${index === 0 ? '' : ','}`;
          messageToastInput += ` ${name}`;
        }
      });

      return toast.warning(
        `${messageToastInput}${
          inputArr.length === 1
            ? ' deve ser selecionado.'
            : ' devem ser selecionados.'
        } `,
      );
    }
  }, [
    getValues,
    handlesearchRows,
    handleDisableWhenSearch,
    setValue,
    handleGetTurnos,
  ]);

  const handleValidarTesouraria = useCallback(
    async (validadoTesouraria: any) => {
      setLoader(true);
      const valida = await validarTesouraria(validadoTesouraria);
      setLoader(false);
      if (valida) {
        handleSearch();

        toast.success(`Tesouraria validada com sucesso`);
        MySwal.fire({
          title: ``,
          text: 'Deseja imprimir os valores informados?',
          showCancelButton: true,
          confirmButtonColor: '#0065FF',
          cancelButtonColor: '#DE350B',
          confirmButtonText: 'SIM',
          cancelButtonText: 'NÃO',
        }).then(async (result: any) => {
          if (result.isConfirmed) {
            handleImprimir();
          }
        });
      }
    },
    [MySwal, handleImprimir, handleSearch],
  );

  const handleValidar = useCallback(async () => {
    const statusTesouraria = await handleGetStatusTesouraria();
    const tesourariaConferida = statusTesouraria.some(
      (item: any) => item.tipo_status === 0 || item.tipo_status === 2,
    );

    if (!tesourariaConferida) {
      return toast.warning('Tesouraria já está conferida!');
    }

    let empresaValToleranciaTes = await getToleranciaEmpresa();

    if (!empresaValToleranciaTes) {
      empresaValToleranciaTes = 0.0;
    }
    const verificar = await VerificarTesouraria(
      searchRows,
      empresaValToleranciaTes,
    );

    if (verificar) {
      MySwal.fire({
        icon: 'warning',
        title: ``,
        text: 'Valores informados estão dentro do valor de tolerância!',
        showCancelButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'SIM para usar a tolerância.',
        cancelButtonText:
          'NÃO para continuar a conferência na tela de liberação.',
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          const searchRowValidado = {
            insert: [],
            update: [],
          };
          let validadoTesouraria: any = searchRowValidado;

          for (let index = 0; index < searchRows.length; index++) {
            const validado = ValidarToleranciaTesouraria(
              searchRows[index],
              empresaValToleranciaTes,
              searchRowValidado,
            );
            validadoTesouraria = validado;
          }

          handleValidarTesouraria(validadoTesouraria);
        }
      });
    } else {
      return toast.warning('Operação cancelada');
    }
  }, [handleGetStatusTesouraria, MySwal, searchRows, handleValidarTesouraria]);

  const handleCancel = useCallback(() => {
    handleTotal('0,00');

    const fields = getValues();
    const registeredFields = Object.keys(fields);
    registeredFields.forEach((element) => {
      if (element.includes('cod_tesouraria-')) {
        unregister(`${element}`);
      }
    });

    handlesearchRows([]);
    handledisableButtonClear(false);
    handledisableButtonCancel(true);
    setDisableButtonValidar(true);
    handleDisableWhenSearch(false);
    setDisabledButtonImprimir(true);
  }, [
    handleTotal,
    handledisableButtonCancel,
    handledisableButtonClear,
    handlesearchRows,
    handleDisableWhenSearch,
    getValues,
    unregister,
  ]);

  const handleDelete = useCallback(async () => {
    const conferido = searchRows.some((item: any) => item.tipo_status === 0);
    if (conferido) {
      await MySwal.fire({
        icon: 'warning',
        title: ``,
        text: 'Deseja realmente excluir?',
        showCancelButton: true,
        confirmButtonColor: '#0065FF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          const { operador, caixa, dta_mov, lojas, turno } = getValues();
          const params = {
            cod_loja: lojas,
            cod_caixa: caixa.value,
            dta_mov,
            cod_operador: operador.value,
            num_turno: turno,
          };
          deleteTesouraria(params);
          handleCancel();
        }
      });
    } else {
      toast.warning('Tesouraria já conferida, não é possivel excluir.');
    }
  }, [MySwal, searchRows, getValues, handleCancel]);

  const handleGetOperador = useCallback(async () => {
    const { dta_mov, lojas, caixa } = getValues();
    const operadores = await getOperador(dta_mov, lojas, caixa.value);

    if (operadores.length === 0) {
      return toast.warning('Operador não encontrado para o caixa selecionado');
    }
    handledisableOperador(false);
    handleOptionsOperador(operadores);
  }, [getValues, handleOptionsOperador, handledisableOperador]);

  const handleChangeCaixa = useCallback(() => {
    const caixa = getValues('caixa');
    setValue('operador', '');
    setValue('turno', '');
    if (caixa) {
      handleGetOperador();
    } else {
      handledisableOperador(true);
    }
  }, [getValues, handleGetOperador, handledisableOperador, setValue]);

  return (
    <ConferenciaTesourariaContext.Provider
      value={{
        handleSearch,
        loaderSearch,
        setValue,
        register,
        errors,
        getValues,
        reset,
        control,
        handledisableCaixa,
        handledisableTurno,
        handledisableOperador,
        handledisableButtonCancel,
        handledisableButtonClear,
        disableCaixa,
        disableTurno,
        disableOperador,
        disableButtonCancel,
        disableButtonClear,
        disabledButtonImprimir,
        searchRows,
        handlesearchRows,
        handleTotal,
        total,
        handleLoja,
        lojaConferencia,
        handleOptionsTurno,
        optionsTurno,
        handleOptionsOperador,
        optionsOperador,
        handleOptionsPdv,
        optionsPdv,
        disableButtonValidar,
        resetInput,
        handleCancel,
        handleGetTurnos,
        handleChangeCaixa,
        handleGetPdv,
        handleChangeLojaAndDate,
        handleValidar,
        handleImprimir,
        handleDelete,
        setFocus,
        disableInputs,
        handleDisableWhenSearch,
        disableButtonSearch,
        loader,
      }}
    >
      {children}
    </ConferenciaTesourariaContext.Provider>
  );
}

export const useConferenciaTesouraria =
  (): ConferenciaTesourariaContextData => {
    return useContext(ConferenciaTesourariaContext);
  };
