import api from '~/services/api';
import { nanoid } from 'nanoid';
import { fltRound, formatCurrencyAsText } from '~/utils/functions';
import { SearchProps } from '../../protocols/SearchProps';

export async function getSearch(
  dta_mov: string,
  num_pdv: number,
  cod_operador: number,
  cod_loja: number,
  num_turno: number,
): Promise<SearchProps[]> {
  try {
    const { data } = await api.get(
      `/conferencia-tesouraria/search/${num_turno}/${dta_mov}/${num_pdv}/${cod_operador}/${cod_loja}`,
    );

    if (data.data) {
      const search = data.data.map((value: any) => {
        value.id = nanoid();
        value.val_dif = formatCurrencyAsText(
          Math.abs(fltRound(value.val_mov - value.val_conf, 2)),
        );
        value.val_conf = formatCurrencyAsText(value.val_conf);

        value.val_mov = formatCurrencyAsText(value.val_mov);

        return value;
      });
      return search;
    }
    return [];
  } catch (error: any) {
    return [];
  }
}
