import api from '~/services/api';
import { toast } from 'react-toastify';
import { validarTesourariaProps } from '../../protocols/TesourariaProps';

export async function validarTesouraria(
  itemTesouraria: validarTesourariaProps,
): Promise<number | string | undefined> {
  try {
    const { data } = await api.post(`/conferencia-tesouraria`, {
      itemTesouraria,
    });

    if (data.success) {
      return data.success;
    }
  } catch (error: any) {
    return toast.warning(error.message);
  }
}
