import api from '~/services/api';
import { statusTesourariaProps } from '../../protocols/TesourariaProps';

export async function getStatusTesouraria(
  dta_mov: string,
  num_pdv: number,
  cod_loja: number,
): Promise<statusTesourariaProps[] | []> {
  try {
    const { data } = await api.get(
      `/conferencia-tesouraria/status/${dta_mov}/${cod_loja}/${num_pdv}`,
    );
    return data.data;
  } catch (error: any) {
    return [];
  }
}
