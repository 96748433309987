import api from '~/services/api';
import { SelectProps } from '../../protocols/SelectProps';

export async function getPdvs(
  dta_mov: string,
  cod_loja: number,
): Promise<SelectProps[]> {
  try {
    const { data } = await api.get(
      `/conferencia-tesouraria/pdvs/${dta_mov}/${cod_loja}`,
    );
    if (data.data) {
      const pdvs = data.data
        .filter((v: any) => v.flg_inativo !== true)
        .map((value: any) => {
          return value;
        });

      return pdvs;
    }
    return [];
  } catch (error: any) {
    return [];
  }
}
