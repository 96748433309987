import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useConferenciaTesouraria } from '../../ConferenciaTesourariaContext';

const Total: React.FC = () => {
  const { total } = useConferenciaTesouraria();

  return (
    <Row
      style={{
        marginTop: '10px',
      }}
    >
      <Col
        md={12}
        sm={12}
        style={{
          fontSize: '22px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        Total: R$: {total}
      </Col>
    </Row>
  );
};

export default Total;
