import api from '~/services/api';

export async function getToleranciaEmpresa(): Promise<number> {
  try {
    const { data } = await api.get(`/conferencia-tesouraria/toleranciaEmpresa`);
    return data.data.val_tolerancia_tes;
  } catch (error: any) {
    return 0.0;
  }
}
