import React from 'react';

import FooterDefault from '~/components/FooterDefault';
import { Container, SeparatorLine, Title, Button } from './styles';
import Loja from '~/components/Loja';
import { Row, Col } from 'react-bootstrap';
import { InputDate, InputSelect } from '~/components/NovosInputs';
import { CancelButton, ConfirmButton } from '~/components/Buttons';

import { MdCheck, MdPrint } from 'react-icons/md';

import { AiOutlineMinusCircle } from 'react-icons/ai';

import { useConferenciaTesouraria } from './ConferenciaTesourariaContext';
import ContainerButtonCapa from './Component/ContainerButtonCapa';
import { DataGridComponent } from './Component/DataGridComponent';
import Total from './Component/Total';
import DefaultLoader from '~/components/DefaultLoader';
import Tooltip from '@atlaskit/tooltip';
import { HotKeys, configure } from 'react-hotkeys';
import { useQueryClient } from 'react-query';

export const ConferenciaTesourariaContent: React.FC = () => {
  const {
    setValue,
    register,
    errors,
    control,
    disableCaixa,
    disableTurno,
    disableOperador,
    handleLoja,
    lojaConferencia,
    optionsTurno,
    optionsOperador,
    optionsPdv,
    disableButtonValidar,
    disabledButtonImprimir,
    handleChangeCaixa,
    handledisableCaixa,
    handleChangeLojaAndDate,
    handleValidar,
    handleImprimir,
    handleDelete,
    disableInputs,
    loader,
  } = useConferenciaTesouraria();

  const queryClient = useQueryClient();

  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ignoreEventsCondition(keyEvent: KeyboardEvent) {
      return false;
    },
  });

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  const keyMap = {
    hkSalvar: 'ctrl+alt+s',
    hkDelete: 'ctrl+alt+d',
  };

  const validaTelaAtalho = () => {
    const cod_tela_active: any = queryClient.getQueryData(`cod_tela_active`);
    if (cod_tela_active) {
      if (cod_tela_active.cod_tela === 277) {
        return true;
      }
    }

    return false;
  };

  const keyHandlers = {
    hkSalvar: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (!disableButtonValidar) {
          handleValidar();
        }
      }
    },
    hkDelete: () => {
      const validaAtalho = validaTelaAtalho();
      if (validaAtalho) {
        if (!disableButtonValidar) {
          handleDelete();
        }
      }
    },
  };

  return (
    <HotKeys id="hotkeys" keyMap={keyMap} handlers={keyHandlers} tabIndex={-1}>
      <Container>
        <Title>Conferência da Tesouraria</Title>
        <SeparatorLine />

        <Row>
          <Col md={12} sm={12}>
            <Loja
              onChange={(val: any) => {
                handleLoja(val);
                setValue('lojas', val);
                handleChangeLojaAndDate();
              }}
              selectedLoja={lojaConferencia}
              isDisabled={disableInputs}
            />
          </Col>
        </Row>
        <Row>
          <Col md={2} sm={12}>
            <InputDate
              label="Data Mov."
              placeholder=""
              name="dta_mov"
              register={register}
              isError={!!errors.dta_mov}
              control={control}
              onBlur={() => {
                handleChangeLojaAndDate();
              }}
              onChange={(event: any) => {
                setValue('dta_mov', event.target?.value);
                handledisableCaixa(false);
              }}
              disabled={disableInputs}
            />
          </Col>
          <Col md={2} sm={12}>
            <InputSelect
              label="Caixa"
              placeholder="Selecione..."
              name="caixa"
              register={register}
              isError={!!errors.caixa}
              control={control}
              options={optionsPdv}
              changeSelected={(selected) => {
                setValue('caixa', selected);

                handleChangeCaixa();
              }}
              isDisabled={disableCaixa}
            />
          </Col>
          <Col md={6} sm={12}>
            <InputSelect
              label="Operador"
              placeholder="Selecione..."
              name="operador"
              register={register}
              isError={!!errors.operador}
              control={control}
              options={optionsOperador}
              changeSelected={(selected) => setValue('operador', selected)}
              isDisabled={disableOperador}
            />
          </Col>
          <Col md={2} sm={12}>
            <InputSelect
              label="Turno"
              placeholder="Selecione..."
              name="turno"
              register={register}
              isError={!!errors.turno}
              control={control}
              options={optionsTurno}
              changeSelected={(selected) => setValue('turno', selected)}
              isDisabled={disableTurno}
            />
          </Col>
        </Row>
        <ContainerButtonCapa />

        <DataGridComponent />
        <Total />

        <FooterDefault codTela={277}>
          <Tooltip position="bottom" content="CTRL + ALT + D">
            <CancelButton
              disabled={disableButtonValidar}
              onClick={() => handleDelete()}
            >
              <AiOutlineMinusCircle
                focusable={false}
                aria-hidden="true"
                size={20}
                style={{
                  marginTop: '-2px',
                  color: '#fff',
                  marginRight: '5px',
                }}
              />
              Excluir
            </CancelButton>
          </Tooltip>
          <Button
            disabled={disabledButtonImprimir}
            onClick={() => handleImprimir()}
            className="imprimir"
          >
            <MdPrint size={20} style={{ marginRight: '5px' }} />
            Imprimir
          </Button>
          <Tooltip position="bottom" content="CTRL + ALT + S">
            <ConfirmButton
              style={{ marginLeft: '5px', marginRight: '-10px' }}
              disabled={disableButtonValidar}
              onClick={() => handleValidar()}
            >
              <MdCheck size={20} style={{ marginRight: '5px' }} />
              Validar
            </ConfirmButton>
          </Tooltip>
        </FooterDefault>
      </Container>
    </HotKeys>
  );
};
