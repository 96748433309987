import { fltRound, transformAsCurrency } from '~/utils/functions';
import { SearchProps } from '../protocols/SearchProps';

export function VerificarTesouraria(
  searchRows: SearchProps[],
  empresa: number,
): boolean {
  let resposta = true;
  for (let index = 0; index < searchRows.length; index++) {
    if (transformAsCurrency(searchRows[index].val_dif) !== 0.0) {
      if (
        fltRound(transformAsCurrency(searchRows[index].val_dif), 2) >=
        fltRound(empresa, 2)
      ) {
        resposta = false;
      }
    }
  }

  return resposta;
}
